/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Hydrovision from "../../assets/hydrovision.jpg";
import Facebook from "../../assets/facebook.png";
import Twitter from "../../assets/twitter.png";
import Google from "../../assets/google.png";
import Eyeopen from "../../assets/eye.png";
import Password from "../../assets/reset-password.png";
import "./home.css";
import { Link } from "react-router-dom";
import Gmail from "../../assets/gmail.png";
import Admin from "../../assets/admin.png";
import Login from "../../assets/login.png";
import Register from "../../assets/register.png";

const home = () => {
  const [isPanel, setIsPanel] = useState(false);
  const [isSlide, setIsSlide] = useState(true);
  const [isLoginRegister, setIsLoginRegister] = useState(false);

  const handleSignUpClick = () => {
    setIsPanel(!isPanel);
    setIsSlide(!isSlide);
  };

  const handleSignInClick = () => {
    setIsPanel(!isPanel);
    setIsSlide(!isSlide);
  };

  const handleLoginClick = () => {
    setIsLoginRegister(!isLoginRegister);
  };

  const handleRegisterClick = () => {
    setIsLoginRegister(!isLoginRegister);
  };

  return (
    <>
      <div className="container1">
        <div className="container">
          <div
            className={isLoginRegister ? "left left2" : "left left1"}
            id="left"
          >
            <div className="left_first">
              <img
                src={Hydrovision}
                alt="Hydrovision"
                className="left_first_img"
              />
              <span>HydroVision</span>
            </div>
            <div className="left_second">
              <h2>Login to Your Account</h2>
              <p className="login_p">Login using social networks</p>
              <div className="social_net">
                <Link to="#" className="Link">
                  <img src={Facebook} alt="" />
                </Link>
                <Link to="#" className="Link">
                  <img src={Google} alt="" />
                </Link>
                <Link to="#" className="Link">
                  <img src={Twitter} alt="" />
                </Link>
              </div>
              <span className="Or">Or</span>
              <form action="">
                <div className="input_field">
                  <img src={Gmail} alt="" />
                  <input
                    type="email"
                    placeholder="Email"
                    name="lemail"
                    id="lemail"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>
                <div className="input_field">
                  <img src={Password} alt="" />
                  <input
                    type="password"
                    placeholder="Password"
                    name="lpassword"
                    id="lpassword"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                  <img src={Eyeopen} alt="pass" />
                </div>
                <div className="more_opt">
                  <div className="remember">
                    <input type="checkbox" name="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">Remember Me</label>
                  </div>
                  <div className="forget">
                    <Link to="#" name="click" id="click">
                      Forget Password?
                    </Link>
                  </div>
                </div>
                <button id="Login">Login</button>
                <div className="sign_up">
                  <p className="sign_p">New to website?</p>
                  <Link
                    className="sign_a"
                    id="Register_page"
                    onClick={handleLoginClick}
                  >
                    Register Here
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div
            className={isLoginRegister ? "right right1" : "right right2"}
            id="right"
          >
            <div className="right_first">
              <img
                src={Hydrovision}
                alt="Hydrovision"
                className="right_first_img"
              />
              <span>HydroVision</span>
            </div>
            <div className="right_second">
              <h2>Create Account</h2>
              <p className="register_p">Register using social networks</p>
              <div className="social_net">
                <Link to="#" className="Link">
                  <img src={Facebook} alt="" />
                </Link>
                <Link to="#" className="Link">
                  <img src={Google} alt="" />
                </Link>
                <Link to="#" className="Link">
                  <img src={Twitter} alt="" />
                </Link>
              </div>
              <span className="Or">Or</span>
              <form action="">
                <div className="input_field">
                  <img src={Admin} alt="" />
                  <input
                    type="name"
                    placeholder="Name"
                    name="name"
                    id="name"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>
                <div className="input_field">
                  <img src={Gmail} alt="" />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>
                <div className="input_field">
                  <img src={Password} alt="" />
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                  <img src={Eyeopen} alt="pass" />
                </div>
                <div className="input_field">
                  <img src={Password} alt="" />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="cpassword"
                    id="cpassword"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                  />
                  <img src={Eyeopen} alt="cpass" />
                </div>
                <button id="Register">Register</button>
                <div className="sign_in">
                  <p className="sign_p">Already Registered?</p>
                  <Link
                    className="sign_a"
                    id="Login_page"
                    onClick={handleRegisterClick}
                  >
                    Login Here
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={isPanel ? "panel panel2" : "panel panel1"}>
          <div
            className={
              isSlide
                ? "left_panel .left_panel1 right_panel2"
                : "left_panel left_panel2 right_panel1"
            }
            id="left_panel"
          >
            <div className="content">
              <h3>New User</h3>
              <p>Click here to Register...</p>
              <button
                className="btn btn1"
                id="sign-up-btn"
                onClick={handleSignUpClick}
              >
                Sign up
              </button>
            </div>
            <img src={Register} className="right_panel_img" alt="" />
          </div>
          <div
            className={
              isSlide
                ? "right_panel right_panel1 left_panel2"
                : "right_panel right_panel2 left_panel1"
            }
            id="right_panel"
          >
            <div className="content">
              <h3>Already Registered?</h3>
              <p>Click here to Login...</p>
              <button
                className="btn btn1"
                id="sign-in-btn"
                onClick={handleSignInClick}
              >
                Sign In
              </button>
            </div>
            <img src={Login} className="left_panel_img" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
